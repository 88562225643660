import axios from 'axios';
import store from '../store/index';

// Switch for prod or dev
const dev = false;

export const API = dev ? "https://hubapi-staging.yorkengine.com" : "https://hubapi.yorkengine.com";
export const SYNAPSE = "https://sks-staging.studyexplorer.io/graphql";

export const hubApi = () => {
  const apiURL = API;
  const token = dev ? process.env.REACT_APP_STAGING_TOKEN : process.env.REACT_APP_API_TOKEN;
  const me = {};

  me.endPoints = {
    auth: '/auth',
    authToken: '/api/v1/token/',
    rallies: '/api/v1/analysis',
    updateRally: '/analysis/edit/',
    createRally: '/api/v1/create/analysis',
    users: '/api/v1/user',
    resetPassword: '/reset/password',
    emailPassword: '/reset/email',
    createUser: '/people/add',
    editUser: '/people/edit/',
    updateUser: '/user/edit/',
    studies: '/api/v1/study',
    default: '/api/v1',
    upload: '/upload/'
  };

  // Function to return XMLHttpRequest for Auth actions
  function addXMLHeader() {
    return {
      headers: {
        'Authorization' : 'Bearer ' + token,
        'X-Requested-With': 'XMLHttpRequest'
      }
    };
  }

  function defaultHeader() {
    return {
      headers: {
        'Authorization' : 'Bearer ' + token,
      }
    }
  }

  /**
   * Do a GET request using axios.
   * @param {string} endPoint [Required]
   * @param {Object=} header
   * @returns {Promise.<Object>}
   */
  me.get = async function (endPoint, header) {
    let options;
    if (header) {
      options = addXMLHeader();
    } else {
      options = defaultHeader();
    }
    if (endPoint) {
      const url = `${apiURL}${endPoint}`;
      return axios.get(url, options);
    }
    throw new Error('Missing required argument endPoint in getRequest');
  };

  /**
   * Do a POST request using axios.
   * @param {string} endPoint [Required]
   * @param {Object} data [Required]
   * @param {Object=} header
   * @returns {Promise.<Object>}
   */
  me.post = async function (endPoint, data, header) {
    let options;
    if (header) {
      options = addXMLHeader();
    } else {
      options = defaultHeader();
    }
    if (endPoint && data) {
      const url = `${apiURL}${endPoint}`;
      if (options) {
        return axios.post(url, data, options);
      }
      return axios.post(url, data);
    }
    if (!endPoint) {
      throw new Error('Missing required argument endPoint in postRequest');
    } else {
      throw new Error('Missing required argument data in postRequest');
    }
  };

  /**
   * Do a PUT request using axios.
   * @param {string} endPoint [Required]
   * @param {Object} data [Required]
   * @param {Object=} header
   * @returns {Promise.<Object>}
   */
  me.put = async function (endPoint, data, header) {
    let options;
    if (header) {
      options = addXMLHeader();
    } else {
      options = defaultHeader();
    }
    if (endPoint && data) {
      const url = `${apiURL}${endPoint}`;
      return axios.put(url, data, options);
    }
    if (!endPoint) {
      throw new Error('Missing required argument endPoint in putRequest');
    } else {
      throw new Error('Missing required argument data in putRequest');
    }
  };

  /**
   * Do a DELETE request using axios.
   * @param endPoint [Required]
   * @param {Object=} header
   * @returns {Promise<Object>}
   */
  me.delete = async function (endPoint, header) {
    let options;
    if (header) {
      options = addXMLHeader();
    } else {
      options = defaultHeader();
    }
    if (endPoint) {
      const url = `${apiURL}${endPoint}`;
      if (options) {
        return axios.delete(url, options);
      }
      return axios.delete(url);
    }
    throw new Error('Missing required endPoint data in deleteRequest');
  };

  return me;
};

export const synapseApi = () => {
  let endpoint = SYNAPSE;
  const me = {};
  const token = process.env.REACT_APP_SYNAPSE_TOKEN;

  const synAxios = axios.create();

  /**
   * Do an POST request using axios.
   * @param {Object} data [Required]
   * @returns {Promise.<Object>}
   */
  me.post = async function (data) {
    if (data) {
      synAxios.interceptors.request.use(function (config) {
        // Do something before request is sent
        config.headers["Authorization"] = "Bearer " + token;
        return config;
      }, function (error) {
        return Promise.reject(error);
      });

      return synAxios.post(endpoint, data);
    }
    throw new Error('Missing required argument Data in updateRequest');
  };

  return me;
};
